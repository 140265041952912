.gradient-border-mask {
  position: relative
}

.gradient-border-mask::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  border: 1px solid transparent;
  background: linear-gradient(45deg, #9C4FFF, #02EDBA) border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}